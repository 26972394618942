import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./config/scrollTop";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import Cart from "./pages/cart/Cart";
import AboutUs from "./pages/footerPages/AboutUs";
import ContactUs from "./pages/footerPages/ContactUs";
import CheckOut from "./pages/checkOut/CheckOut"
import CorporateInfo from "./pages/footerPages/CorporateInfo";
import HelpCanR from "./pages/help/HelpCanR";
import HelpFAQ from "./pages/help/HelpFAQ";
import HelpPayments from "./pages/help/HelpPayments";
import HelpShipping from "./pages/help/HelpShipping";
import Home from "./pages/home/Home";
import PolicyPrivacy from "./pages/policy/PolicyPrivacy";
import PolicyReturn from "./pages/policy/PolicyReturn";
import PolicySecurity from "./pages/policy/PolicySecurity";
import PolicyTermsOfUse from "./pages/policy/PolicyTermsOfUse";
import Product from "./pages/product/Product";
import ProductList from "./pages/product/ProductList";
import Profile from "./pages/profile/Profile";
import { PublicRoute } from "./routes/PublicRoute";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/sub-category/:sub_categoryName/:sub_categoryId" element={<ProductList />} />
          <Route path="/category/:categoryName/:categoryId" element={<ProductList />} />
          <Route path="/profile/:section" element={<Profile />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/corporate-info" element={<CorporateInfo />} />
          <Route path="/help/payment" element={<HelpPayments />} />
          <Route path="/help/shipping" element={<HelpShipping />} />
          <Route path="/help/cancellation-and-return" element={<HelpCanR />} />
          <Route path="/help/faq" element={<HelpFAQ />} />
          <Route path="/policy/privacy" element={<PolicyPrivacy />} />
          <Route path="/policy/security" element={<PolicySecurity />} />
          <Route path="/policy/terms-of-use" element={<PolicyTermsOfUse />} />
          <Route path="/policy/return" element={<PolicyReturn />} />
          <Route path="/check-out" element={<CheckOut />} />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="*"
            element={
              <div className="h-screen w-screen flex justify-center items-center">
                <h1 className="text-3xl">404 Page Not Found</h1>
              </div>
            }
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
