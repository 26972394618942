import React, { useState, useEffect } from "react";
import { UserIcon, ShoppingBagIcon } from "@heroicons/react/solid";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ManageAddress from "../../components/profile/ManageAddress";
import PanInfo from "../../components/profile/PanInfo";
import PersonalInfo from "../../components/profile/PersonalInfo";
import Orders from '../../components/profile/Orders';
import useAuth from "../../config/AuthContext";
import { useToken } from "../../config/useToken";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { section } = useParams();
  const { getUser } = useToken();
  const { logout } = useAuth();
  const [component, setComponent] = useState(section || "personal");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUser(getUser());
    setLoading(false);
  }, []);

  const handleComponent = () => {
    switch (component) {
      case "personal":
        return <PersonalInfo user={user} />;
      case "address":
        return <ManageAddress addressesData={user.address} />;
      case "pan":
        return <PanInfo />;
      case "orders":
          return <Orders />;
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Header />
      <div id="profile-page" className="flex gap-4 my-16 min-h-screen w-screen px-28">
        <div className="flex flex-1 flex-col gap-y-4 h-full w-full">
          <div className="flex gap-4 p-3 bg-white shadow-md border rounded">
            <div className="w-12 h-12 rounded-full border bg-liluPurple" />
            <div className="flex-col">
              <div className="text-liluPurple text-xl font-medium capitalize">
                {user.first_name} {user.last_name}
              </div>
              <div className="text-liluPurple text-sm font-medium">
                {user.email}
              </div>
              {/* LOGOUT BTN */}
              <button
                className="bg-liluPurple text-white text-sm font-medium py-2 px-4 rounded w-full mt-4"
                onClick={() => logout()}
              >
                Logout
              </button>
            </div>
          </div>
          <div className="flex-1 h-full gap-4 bg-white shadow-md border rounded">
            <div className="flex flex-col w-full p-4 border-b">
              <div className="flex gap-5 items-center mb-4">
                <UserIcon className="w-6 h-6 text-blue-600" />
                <p className="text-gray-500 font-semibold">ACCOUNT SETTINGS</p>
              </div>
              <div
                className="flex gap-5 items-center py-2 cursor-pointer"
                onClick={() => setComponent("personal")}
                style={component === "personal" ? { color: "#5f4cf2" } : {}}
              >
                <div className="w-6 h-6" />
                <p className="text-sm">Personal Information</p>
              </div>
              <div
                className="flex gap-5 items-center py-2 cursor-pointer"
                onClick={() => setComponent("address")}
                style={component === "address" ? { color: "#5f4cf2" } : {}}
              >
                <div className="w-6 h-6" />
                <p className="text-sm">Manage Addresses</p>
              </div>
              <div
                className="flex gap-5 items-center py-2 cursor-pointer"
                onClick={() => setComponent("pan")}
                style={component === "pan" ? { color: "#5f4cf2" } : {}}
              >
                <div className="w-6 h-6" />
                <p className="text-sm">PAN Information</p>
              </div>
            </div>
            <div className="flex flex-col w-full p-4 border-b"
              onClick={() => setComponent("orders")}
            >
              <div className="flex gap-5 items-center mb-4">
                <ShoppingBagIcon className="w-6 h-6 text-blue-600" />
                <p className="text-gray-500 font-semibold">ORDERS</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-3 w-full bg-white shadow-md border rounded">
          {handleComponent()}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
