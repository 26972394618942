import React, { useState } from "react";
import { useToken } from "../../config/useToken";

const AddressCard = ({ addressData }) => {
  const {
    name,
    address_type,
    address_line_1,
    address_line_2,
    phone_number,
    pin_code,
    city,
    state,
    country,
  } = addressData;
  const { editAddress, deleteAddress } = useToken();
  const [address, setAddress] = React.useState(addressData);
  const [showEdit, setShowEdit] = useState(false);
  return (
    <div className="flex flex-col p-6 border-b">
      <div className="flex h-full items-center justify-between">
        <div className="bg-gray-100 rounded py-1 px-2">
          <p
            className="font-medium uppercase text-gray-500"
            style={{ fontSize: "11px" }}
          >
            {address_type}
          </p>
        </div>
        <div className="flex items-center">
          <button 
            className="text-gray-600 font-medium text-xs hover:text-blue-500"
            onClick={() => setShowEdit(true)}
          >
            Edit
          </button>
          <button 
            className="ml-2 text-gray-600 font-medium text-xs hover:text-red-500"
            onClick={() => {
              deleteAddress(address);
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <div className="flex gap-8 my-4">
        <p className="text-sm font-medium text-gray-800">{name}</p>
        <p className="text-sm font-medium text-gray-800">{phone_number}</p>
      </div>
      <p className="text-sm text-gray-800 w-3/5">
        {address_line_1}, {address_line_2}, <br /> {city} {state} {country} -{" "}
        <span className="font-semibold">{[pin_code]}</span>
      </p>
      {showEdit && (
        <div className="border-t mt-5 py-3">
          <div className="flex flex-col flex-wrap h-72">
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Type
              </label>
              <select
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                value={address.address_type}
                onChange={(e) => {
                  setAddress({ ...address, address_type: e.target.value });
                  console.log(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
              </select>
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Name
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Name"
                value={address.name}
                onChange={(e) => {
                  setAddress({ ...address, name: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Phone
              </label>
              <input
                type="tel"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Phone"
                maxLength={10}
                value={address.phone_number}
                onChange={(e) => {
                  setAddress({ ...address, phone_number: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Address Line 1
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Address Line 1"
                value={address.address_line_1}
                onChange={(e) => {
                  setAddress({ ...address, address_line_1: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Address Line 2
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Address Line 2"
                value={address.address_line_2}
                onChange={(e) => {
                  setAddress({ ...address, address_line_2: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                City
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter City"
                value={address.city}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                State
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter State"
                value={address.state}
                onChange={(e) => {
                  setAddress({ ...address, state: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Country
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Country"
                value={address.country}
                onChange={(e) => {
                  setAddress({ ...address, country: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                PIN CODE
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter PIN CODE"
                value={address.pin_code}
                onChange={(e) => {
                  setAddress({ ...address, pin_code: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              className="text-gray-700 border border-gray-700 text-sm font-medium py-2 px-4 rounded w-1/2 mt-4 mr-2"
              onClick={() => {
                setShowEdit(false);
              }}
            >
              Cancel
            </button>
            <button 
              className="bg-liluPurple text-white text-sm font-medium py-2 px-4 rounded w-1/2 mt-4 ml-2"
              onClick={() => {
                setShowEdit(false);
                editAddress(address);
              }}
            >
              Save Address
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressCard;
