import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const HelpCanR = () => {
  const [heading, setHeading] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(true);

  const getHelpCanR = async () => {
    try {
      const res = await axios.get(
        "https://lilucommercial.herokuapp.com/api/policy/cancellation-policy/"
      );
      setBody(res.data.data[0]?.content);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getHelpCanR();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="flex min-h-screen w-screen max-w-7xl mt-14 mx-auto py-8">
        <div className="flex flex-col">
          <div dangerouslySetInnerHTML={{__html: body}}>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HelpCanR;
