import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Menu, Transition } from "@headlessui/react";
import Footer from "../../components/footer/Footer";
import ProductCard from "../../components/productCard/ProductCard";
import Header from "../../components/header/Header";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { sortOptions } from "../../dummy/sortOptions";
import { filters } from "../../dummy/filters";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductList = () => {
  const { sub_categoryId, sub_categoryName, categoryId, categoryName } =
    useParams();
  const GLOBAL_URL = "https://lilucommercial.herokuapp.com/api";
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = async () => {
    const res = sub_categoryId
      ? await axios.get(`${GLOBAL_URL}/products?sub-category=${sub_categoryId}`)
      : await axios.get(`${GLOBAL_URL}/products?category=${categoryId}`);
    setList(res.data.data);
    console.log(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getList();
    console.log(categoryName, categoryId);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <main>
      <Header />
      <div className="bg-white product-list-page">
        <div>
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative z-0 flex items-baseline justify-between pt-24 pb-6 border-b border-gray-200">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 capitalize">
                
                {
                  categoryName ?
                  categoryName:sub_categoryName?sub_categoryName:""
                }
                
              </h1>

              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                href={option.href}
                                className={classNames(
                                  option.current
                                    ? "font-medium text-gray-900"
                                    : "text-gray-500",
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <section aria-labelledby="products-heading" className="pt-6 pb-24">
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                {/* Filters */}
                <form className="hidden lg:block">
                  {filters.map((section) => (
                    <div className="mb-8 border-b pb-4">
                      <h3 className="-my-3 flow-root mb-3">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                      </h3>
                      {/* <Disclosure.Panel className="pt-6"> */}
                      <div className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              type="checkbox"
                              defaultChecked={option.checked}
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      {/* </Disclosure.Panel> */}
                    </div>
                  ))}
                </form>

                {/* Product grid */}
                <div className="lg:col-span-3">
                  {/* Replace with your content */}
                  <div>
                    <div id="product-grid"  className="grid grid-cols-3 gap-2">
                      {loading ? (
                        <div className="text-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <div className="h-72 flex justify-center items-center">
                              <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        list.map((product) => (
                          <ProductCard
                            id={product.id}
                            key={product.id}
                            name={product.name}
                            price={product.price}
                            brand={product.brand}
                            colors={product.colors}
                            sizes={product.sizes}
                            discount={product.discount}
                          />
                        ))
                      )}
                    </div>
                  </div>
                  {/* /End replace */}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ProductList;
