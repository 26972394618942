import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import { useToken } from "./useToken";
import { initialState, reducer } from "./useReducer";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const GLOBAL_URL = "https://lilucommercial.herokuapp.com/api";
  const { setToken, setUser, removeToken, removeUser, getToken } = useToken();
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  const register = async (
    firstName,
    lastName,
    email,
    password,
    phone_number
  ) => {
    await axios
      .post(`${GLOBAL_URL}/auth/register`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone_number: phone_number,
      })
      .then((res) => {
        console.log(res);
      })
      .catch(async (error) => {
        console.log(error.response);
      });
  };

  const login = async (email, password) => {
    var errorMsg=""
    await axios
      .post(`${GLOBAL_URL}/auth/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res.data);
        setToken(res.data.data.token);
        setUser(res.data.data.user);
        setLoading(false);
        window.location.href = "/";
      })
      .catch(async (error) => {
        errorMsg=error.response.data.errors.message ?? error.response.data.errors.detail ?? "Invalid credential";
        setLoginError(error.response.data.error);
        setLoading(false);
        
      });
    return errorMsg;
  };

  const logout = async () => {
    dispatch({ type: "USER", payload: false });
    removeToken();
    removeUser();
    window.location.href = "/login";
  };

  const changePassword = async (oldPass, newPass) => {
    await axios.put(
      `${GLOBAL_URL}/auth/change-password`,
      {
        new_password: newPass,
        old_password: oldPass,
      },
      {
        headers: {
          "Authorization": `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    const checkFunc = async () => {
      setLoading(true);
      if (
        localStorage.getItem("token") !== "" &&
        localStorage.getItem("user") !== null
      ) {
        dispatch({ type: "USER", payload: true });
      } else {
        dispatch({ type: "USER", payload: false });
      }
      setLoading(false);
    };
    checkFunc();
  }, []);

  const memoedValue = useMemo(
    () => ({
      login,
      logout,
      dispatch,
      register,
      changePassword
    }),
    [loading, loginError, state, login, logout, changePassword]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {loading ? <>Loading!</> : !loading && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
