import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { useToken } from "../../config/useToken";
import EmptyCart from "../../assets/img/empty_cart.svg";
import { useNavigate } from "react-router-dom";

const Cart = ({ open, setOpen }) => {
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const { getCart, removeCartItem, increaseQuantity, decreaseQuantity } =
    useToken();

  const calculateSubTotal = () => {
    setCartItems(getCart());
    let total = 0;
    getCart().forEach((item) => {
      total += item.price * item.quantity;
    });
    setSubTotal(total);
  };

  useEffect(() => {
    setCartItems(getCart());
    calculateSubTotal();
    setLoading(false);
  }, [open]);

  if (loading) {
    return <div className="h-72 flex justify-center items-center">
    <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
  </div>;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={{zIndex:20}}
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setTimeout(() => {
            setOpen(false);
            console.log("closed---")
          }, 500);
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div id="cart-sidebar" className="fixed inset-y-0 pt-12 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Shopping cart
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {cartItems.length > 0 ? (
                            cartItems.map((product) => (
                              <li key={product.id} className="py-6 flex">
                                <div className="flex flex-shrink-0 w-24 h-24 border justify-center items-center border-gray-200 rounded-md overflow-hidden">
                                  <img
                                    src={product.image[0]}
                                    className="max-w-full max-h-full object-center object-cover"
                                  />
                                </div>

                                <div className="ml-4 flex-1 flex flex-col">
                                  <div className="flex justify-between text-base font-medium text-gray-900">
                                    <h3 className="flex-2">
                                      <a href={product.href}>{product.name}</a>
                                    </h3>
                                    <p className="flex-1 justify-end text-right ml-4">
                                      {product.price.toLocaleString("en-IN", {
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency: "INR",
                                      })}
                                    </p>
                                  </div>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {product.color}
                                  </p>
                                  <div className="flex-1 flex items-end justify-between text-sm">
                                    <div className="flex border rounded">
                                      <button
                                        type="button"
                                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                        onClick={() => {
                                          setLoading(true);
                                          decreaseQuantity(product);
                                          calculateSubTotal();
                                          setLoading(false);
                                        }}
                                      >
                                        <span className="sr-only">
                                          Decrease quantity
                                        </span>
                                        <MinusIcon
                                          className="h-6 w-6"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <input
                                        type="text"
                                        className="p-2 text-gray-400 w-8 h-6 border-x outline-none text-center"
                                        value={product.quantity}
                                        readOnly
                                      />
                                      <button
                                        type="button"
                                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                        onClick={() => {
                                          setLoading(true);
                                          increaseQuantity(product);
                                          setCartItems(getCart());
                                          calculateSubTotal();
                                          setLoading(false);
                                        }}
                                      >
                                        <span className="sr-only">
                                          Increase quantity
                                        </span>
                                        <PlusIcon
                                          className="h-6 w-6"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>

                                    <div className="flex">
                                      <button
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                        onClick={() => {
                                          setLoading(true);
                                          removeCartItem(product);
                                          setCartItems(getCart());
                                          calculateSubTotal();
                                          setLoading(false);
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                    {cartItems.length === 0 ? (
                      <div
                        id="cart-sidebar-body"
                        className="w-full flex flex-col justify-center items-center"
                        style={{ height: "calc(100% - 3.5rem)" }}
                      >
                        <img
                          className="h-1/2 w-1/2"
                          src={EmptyCart}
                          alt="Empty Cart"
                        />
                        <p className="text-gray-500 text-center mt-4">
                          Your cart is empty
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {cartItems.length > 0 ? (
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <p>
                          {subTotal.toLocaleString("en-IN", {
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency: "INR",
                          })}
                        </p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>
                      <div className="mt-6">
                        <a
                          onClick={() => navigation("/check-out")}
                          href="#"
                          className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Checkout
                        </a>
                      </div>
                      <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                        <p>
                          or{" "}
                          <button
                            type="button"
                            className="text-indigo-600 font-medium hover:text-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="mt-6">
                        <a
                          href="#"
                          className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Continue Shopping
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Cart;
