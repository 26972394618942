import React from "react";

const PanInfo = () => {
  return (
    <div id="panInfoPage" className="h-full w-full flex flex-col p-6">
      <div className="flex items-center mb-4">
        <h1 className="text-base font-semibold m-0">PAN Card Information</h1>
      </div>
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="PAN Card Number"
          className="border w-1/3 pt-4 pb-2 px-4 rounded-sm text-sm disabled:text-gray-500"
        />
        <input
          type="text"
          placeholder="Full Name"
          className="border w-1/3 pt-4 pb-2 px-4 rounded-sm text-sm disabled:text-gray-500"
        />
        <input
          type="file"
          className="border w-1/3 pt-4 pb-2 px-4 rounded-sm text-sm disabled:text-gray-500"
        />
        <div className="flex flex-row gap-2 w-full mt-4">
          <input
            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            class="form-check-label inline-block text-gray-800 w-full"
            for="flexCheckDefault"
          >
            I do hereby declare that PAN furnished/stated above is correct and
            belongs to me, registered as an account holder with
            www.flipkart.com. I further declare that I shall solely be held
            responsible for the consequences, in case of any false PAN
            declaration.
          </label>
        </div>
        <button className="bg-liluPurple text-white text-sm font-semibold py-2 px-4 w-24 rounded-sm mt-4 mb-6">
            UPLOAD
        </button>
        <p className="text-indigo-500 font-semibold">Read Terms & Conditions of PAN Card Information</p>
      </div>
    </div>
  );
};

export default PanInfo;
