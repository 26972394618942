import React, { useEffect, useState } from "react";
import useAuth from "../../config/AuthContext";
import Header from "../../components/header/Header";
import logo from "../../assets/img/logo2.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const { register } = useAuth();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleRegister = () => {
    register(first_name, last_name, email, password, phone_number);
  };

  return (
    <>
      <Header hideLogin={true} />
      <div id="registration-page" className="flex h-screen w-screen">
        <div id="registration-form-container" className="flex flex-1 items-center justify-center min-h-screen">
          <div className="px-8 py-6 w-120 mt-4 text-left bg-white">
            <h3 className="text-2xl font-bold">Create new Account!</h3>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label className="block" for="first_name">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="w-full px-4 py-2 mt-1 border rounded-md focus:outline-none"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block" for="last_name">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="w-full px-4 py-2 mt-1 border rounded-md focus:outline-none"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-3">
                <label className="block" for="email">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-1 border rounded-md focus:outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <label className="block">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 mt-1 border rounded-md focus:outline-none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <label className="block">Phone Number</label>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  className="w-full px-4 py-2 mt-1 border rounded-md focus:outline-none"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="flex items-baseline mb-4 justify-between">
                <button
                  className="flex-1 px-6 py-2 mt-4 text-white bg-liluPurple rounded-lg hover:bg-blue-900"
                  onClick={handleRegister}
                >
                  Register
                </button>
              </div>
              <div className="flex justify-end">
                <a
                  href="/login"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Already have an account?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="img-container" className="flex-1 border-2 border-r">
          <img src={logo} alt="logo" className="h-screen" />
        </div>
      </div>
    </>
  );
};

export default Login;
