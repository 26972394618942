import React from "react";
import { StarIcon } from "@heroicons/react/solid";

const Review = ({ start, heading, body, images, from }) => {
  return (
    <div className="w-full border-b p-5">
      <div className="h-8 w-full flex flex-row items-start mb-4">
        {/* STARS */}
        <div className="flex justify-evenly items-center mr-4 bg-liluPurple rounded-full py-0.5 px-2 w-11">
          <StarIcon className="text-white w-4 h-4" />
          <p className="text-white text-sm">{start}</p>
        </div>
        {/* FROM */}
        <p className="text-gray-500 text-sm font-semibold capitalize">{from}</p>
      </div>
      <div className="mb-4">
        {/* HEADING */}
        <h3 className="text-liluPurple capitalize font-semibold mb-2">
          {heading}
        </h3>
        {/* BODY */}
        <p className="text-gray-500 text-sm">{body}</p>
      </div>
      {/* IMAGES */}
      <div className="flex flex-row flex-wrap gap-2 items-center">
        {images.map((image, idx) => (
          <div className="h-20 w-20 p-2 border rounded">
            <img
              key={idx}
              src={image}
              alt="Product"
              className="max-h-full max-w-full mx-auto object-cover object-center"
            />
          </div>
        ))}
        </div>
    </div>
  );
};

export default Review;
