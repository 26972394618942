import React, { useState, useEffect } from "react";
import { SearchIcon, ShoppingCartIcon, HeartIcon } from "@heroicons/react/solid";
import logo from "../../assets/img/logo.png";
import logo3 from "../../assets/img/logo3.png";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../config/useToken";
import Cart from "../../pages/cart/Cart";
import Wishlist from "../../pages/wishlist/Wishlist"

const Header = ({ hideLogin }) => {
  const [cartOpen, setCartOpen] = useState(false);
  const [wishListOpen, setWishListOpen] = useState(false);
  const navigation = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getUser } = useToken();

  useEffect(() => {
    setUser(getUser());
    setLoading(false);
  }, []);

  return (
    <>
      <div id="header-lg" className="flex top-0 z-50 justify-between fixed w-screen bg-liluPurple h-14 drop-shadow">
        <div id="header-gutter" className="h-full min-w-7"></div>
        <div className="flex justify-between h-full w-full max-w-7xl">

          {/* logo */}
          <div id="logo-box" className="flex h-full w-48 justify-between items-center">
            <a href="/">
              <img src={logo3} alt="logo" className="h-16 mr-4" />
            </a>
          </div>

          {/* search box */}
          <div
            className="flex justify-center items-center h-full my-0 min-w-18"
            style={{
              width: "calc(100% - 540px)",
            }}
          >
            <div className="w-full max-w-xl h-9 py-0 px-2 flex">
              <input
                type="text"
                className="rounded-l-sm bg-white border-none outline-none h-full py-0 px-3 text-sm"
                placeholder="Search for products, brands and more"
                style={{
                  width: "calc(100%)",
                }}
              />
              <button className="rounded-r-sm bg-white border-none outline-none cursor-pointer h-full w-9 p-0">
                <SearchIcon className="text-indigo-500 h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Login */}
          <div className="w-16 mx-5 flex justify-center items-center font-medium text-white">
            {user !== null ? (
              <button
                className="text-white px-8 py-1 rounded-sm font-medium capitalize"
                onClick={() => navigation("/profile")}
              >
                {user.first_name}
              </button>
            ) : (
              !loading &&
              (!hideLogin ? (
                <button
                  className="bg-white text-indigo-500 px-8 py-1 rounded-sm font-medium"
                  onClick={() => navigation("/login")}
                >
                  Login
                </button>
              ) : (
                <></>
              ))
            )}
          </div>

          {/* WishList */}
          <div
            className="ml-5 flex justify-center items-center font-medium text-white cursor-pointer"
            onClick={() => { if (!wishListOpen) { setWishListOpen(true) } }}
          >
            <HeartIcon className="h-5 w-5 mr-2" />
            Wishlist
          </div>

          {/* Cart */}
          <div
            className="w-16 ml-5 flex justify-center items-center font-medium text-white cursor-pointer"
            onClick={() => { if (!cartOpen) { setCartOpen(!cartOpen); console.log("Open cart", cartOpen) } }}
          >
            <ShoppingCartIcon className="h-5 w-5 mr-2" />
            Cart
          </div>


        </div>
        <div className="h-full min-w-7"></div>
      </div>
      {
        <div id="header-md" className="top-0 z-50 justify-between fixed w-screen bg-liluPurple drop-shadow">

          <div className="flex" style={{ height: '3rem', justifyContent: 'space-between', width: '100%' }} >

            {/* logo */}
            <div id="logo-box" className="flex h-full w-48 justify-between items-center">
              <a href="/">
                <img src={logo3} alt="logo" className="h-16 mr-4" />
              </a>
            </div>
            <div className="flex">

              {/* WishList */}
              <div
                className="ml-5 flex justify-center items-center font-medium text-white cursor-pointer"
                onClick={() => { if (!wishListOpen) { setWishListOpen(true) } }}
              >
                <HeartIcon className="h-5 w-5 mr-2" />

              </div>


              {/* Cart */}
              <div
                style={{ width: '2rem', marginLeft: '0.5rem' }}
                className="flex justify-center items-center font-medium text-white cursor-pointer"
                onClick={() => { if (!cartOpen) { setCartOpen(!cartOpen); console.log("Open cart", cartOpen) } }}
              >
                <ShoppingCartIcon className="h-5 w-5 mr-2" />

              </div>

              {/* Login */}
              { !hideLogin &&

                <div className="w-16 mx-5 flex justify-center items-center font-medium text-white">
                  {user !== null ? (
                    <button
                      className="text-white pr-8 py-1 rounded-sm font-medium capitalize"
                      onClick={() => navigation("/profile")}
                    >
                      {user.first_name}
                    </button>
                  ) : (
                    !loading &&
                    (!hideLogin ? (
                      <button
                        className="  rounded-sm font-medium"
                        onClick={() => navigation("/login")}
                      >
                        Login
                      </button>
                    ) : (
                      <></>
                    ))
                  )}
                </div>
              }
            </div>


          </div>

          {/* Search */}
          <div className="flex justify-between h-full w-full max-w-7xl">
            <div
              className="flex justify-center items-center h-full my-0 min-w-18"
              style={{
                width: "100%",
              }}
            >
              <div className="w-full max-w-xl h-9 py-0 px-2 flex">
                <input
                  type="text"
                  className="rounded-l-sm bg-white border-none outline-none h-full py-0 px-3 text-sm"
                  placeholder="Search for products, brands and more"
                  style={{
                    width: "calc(100%)",
                  }}
                />
                <button className="rounded-r-sm bg-white border-none outline-none cursor-pointer h-full w-9 p-0">
                  <SearchIcon className="text-indigo-500 h-5 w-5" />
                </button>
              </div>
            </div>

          </div>
        </div>
      }
      <Wishlist open={wishListOpen} setOpen={setWishListOpen} />
      <Cart open={cartOpen} setOpen={setCartOpen} />

    </>
  );
};

export default Header;
