import React, { useState, useEffect } from 'react';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

import { CheckIcon } from '@heroicons/react/solid'
import CheckoutProductCart from '../../components/productCard/CheckoutProductCart';
import { useToken } from "../../config/useToken";
import { useNavigate } from "react-router-dom";



export default function CheckOut() {

    const { getCart, removeCartItem, setCart, increaseQuantity, 
        decreaseQuantity, getUser, validateCoupon, checkOut, wallet } = useToken();
    const navigate = useNavigate();


    const [submitLoading, setSubmitLoading] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [discount, setDiscount] = useState(0);
    const [address, setAddress] = useState("");
    const [addresses, setAddresses] = useState("")
    const [paymentOption, setPaymentOption] = useState("Cash On Delivery")
    const [couponCode, setCouponCode] = useState("")
    const [showInvalidCouponCode, setShowInvalidCouponCode] = useState(false)
    const [balance, setBalance] = useState(0)
    const [walletCash, setWalletCash] = useState(0);
    const [resellingMargin,setResellingMargin]=useState(0);


    const [user, setUser] = useState({})

    const [showModal, setShowModal] = useState(false);

    const [total, setTotal] = useState(0)

    // useEffect(() => {
    //   if(balance<walletCash){

    //   }
    // }, [walletCash])

    const handleWalletChange = event => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setWalletCash(value);
    };
    

    useEffect(() => {
        const getWallet =async()=>{
            var res=await wallet()
            setBalance(res.balance??0)
        }
        getWallet()
     
    }, [])
    

    useEffect(() => {
        var user = getUser();
        setUser(user);
        if (!user) {
            navigate(`/login`);
        }
        else {
            setAddresses(user.address)
            setAddress(user.address[0])
        }
    }, [])


    useEffect(() => {
        var totl = subTotal - (discount/100) * subTotal;
        totl=totl+parseInt(resellingMargin)-parseInt(walletCash);
        setTotal(totl)
    }, [subTotal, discount,resellingMargin,walletCash])


    const calculateSubTotal = () => {
        setCartItems(getCart());
        let total = 0;
        getCart().forEach((item) => {
            total += item.price * item.quantity;
        });
        setSubTotal(total);
    };

    const deleteItem = (product) => {
        setLoading(true);
        removeCartItem(product);
        setCartItems(getCart());
        calculateSubTotal();
        setLoading(false);
    }

    useEffect(() => {
        setCartItems(getCart());
        calculateSubTotal();
        setLoading(false);
    }, []);

    const payNow = async () => {
        

        setSubmitLoading(true)

        if (cartItems.length <= 0) {
            console.log("No Items in the cart")
        }
        else if (subTotal < 0) {
            console.log("Ammount cannot be zero")
        }
        else {

            var products = cartItems.map((itm) => {
                return {
                    "id": itm.id,
                    "amount": itm.price,
                    "quantity": itm.quantity,
                    "color": itm.colorId || 0,
                    "size": itm.size || "",
                    "address_id": (address.id + "") || ""
                }
            })

            var bill = {
                "total_amount": total,
                "coupon_code": couponCode,
                "payment_mode": paymentOption,
                "reselling":resellingMargin>0,
                "reselling_margin":resellingMargin,
                "wallet_amount_used":walletCash,
                products
            }
            var res = await checkOut(bill);
            if (res) {
                await setCart([]);
                navigate("/profile/orders")
            }
            setSubmitLoading(false)
        }
    }

    const redeemCoupon = async () => {
        if (couponCode === "") {
            setShowInvalidCouponCode(false)
            setDiscount(0)
            return
        }
        var res = await validateCoupon(couponCode)
        if (res?.discount) {
            setDiscount(res.discount)
        }
        else {
            console.log(res.data)
            setShowInvalidCouponCode(true)
        }


    }

    return (
        <>
            <Header />
            <div className=" w-screen max-w-7xl mt-14 mx-auto">
                <h2 style={{ paddingTop: "4rem", paddingBottom: "2rem" }} class=" px-4 text-2xl font font-extrabold tracking-tight text-gray-900">Checkout</h2>

                <div className='flex checkout-page' style={{ width: '100%' }}>


                    <div className="flex-4 m-4">
                        <div style={{ boxShadow: "black 0px 0px 15px -10px" }} class="w-full px-10 py-6 shadow-md rounded-md">
                            <h2 class="mr-4 text-2xl font font-semibold tracking-tight text-gray-900" >User Info</h2>
                            <h2 class="mt-3 text-lg font tracking-tight text-gray-400">{user.phone_number}</h2>
                            <h2 class="mt-3 text-lg font tracking-tight text-gray-400">{user.email}</h2>
                            <div class="mt-6 border-t-2" ></div>
                            <h2 class="mt-6 text-2xl font font-semibold tracking-tight text-gray-900" >Delivery Adderss</h2>
                            <div id="delivery-address" style={{ justifyContent: 'space-between', }} class="flex mt-3 ">
                                <h2 class="text-lg font tracking-tight text-gray-400">{address.address_line_1}</h2>
                                <button onClick={() => setShowModal(true)} class="px-6 py-2 bg-liluPurple text-white rounded drop-shadow">Change</button>
                            </div>
                            <div class="mt-6 border-t-2" ></div>
                            <h2 class="mt-6 text-2xl font font-semibold tracking-tight text-gray-900" >Order Summary</h2>

                            {/* Products */}
                            {
                                cartItems.map((product) => {

                                    return (
                                        <CheckoutProductCart
                                            product={product}
                                            increaseQuantity={increaseQuantity}
                                            decreaseQuantity={decreaseQuantity}
                                            calculateSubTotal={calculateSubTotal}
                                            deleteItem={deleteItem}
                                        />
                                    )
                                })
                            }



                            <div id="input-container" style={{ marginTop: '4rem' }} class=" px-4 ml-8">
                                {/* Coupon Code */}
                                <div id="coupon-code" class="flex">
                                    <input
                                        onChange={(e) => { setCouponCode(e.target.value) }}
                                        value={couponCode}
                                        type="text"
                                        placeholder="Coupon Code"
                                        class=""
                                        style={{
                                            display: 'flex',
                                            flex: '1',
                                            border: 'solid 1px',
                                            paddingLeft: '1rem',
                                            textTransform: 'uppercase'
                                        }}
                                    />
                                    <button onClick={redeemCoupon} class="px-8 ml-8 py-2 bg-liluPurple text-white rounded drop-shadow">Apply</button>
                                </div>
                                {
                                    showInvalidCouponCode &&
                                    <span style={{ color: 'red' }} >Invalid coupon code</span>
                                }

                                {/* Use wallet cash */}
                                <div style={{ marginTop: '3rem' }} >
                                    <div class="flex">
                                        <h2 class=" text-2xl font font-semibold tracking-tight text-gray-900" >Use Wallet Cash</h2>

                                        <CheckIcon style={{ color: '#fff', backgroundColor: "black" }} className="h-6 w-6 ml-8" />

                                    </div>
                                    <div class="mt-6">
                                        <input
                                            type="number"
                                            min='0'
                                            max={balance}
                                            value={walletCash}
                                            onChange={handleWalletChange}
                                            placeholder="Enter Amount"
                                            class="py-2"
                                            style={{
                                                display: 'flex',
                                                flex: '1',
                                                border: 'solid 1px',
                                                paddingLeft: '1rem',
                                                width:'15rem'
                                            }}
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <h2 style={{ color: 'green' }}>({balance} Rs left)</h2>
                                    </div>
                                </div>
                               


                                {/* Reselling */}
                                <div style={{ marginTop: '3rem' }} >
                                    <div class="flex">
                                        <h2 class=" text-2xl font font-semibold tracking-tight text-gray-900" >Reselling</h2>

                                        <CheckIcon style={{ color: '#fff', backgroundColor: "black" }} className="h-6 w-6 ml-8" />

                                    </div>
                                    <div class="mt-6">
                                        <input
                                            value={resellingMargin}
                                            type="number"
                                            onChange={(e)=>setResellingMargin(e.target.value)}
                                            placeholder="Enter Margine"
                                            class="py-2"
                                            style={{
                                                display: 'flex',
                                                flex: '1',
                                                border: 'solid 1px',
                                                paddingLeft: '1rem',
                                                width:'15rem'
                                            }}
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <h2 style={{ color: 'green' }}>You earn 600 profit on this account</h2>
                                        <h2 style={{ color: 'green' }}>(Earned profit will credit to your wallet)</h2>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div style={{}} className="flex-2 m-4">
                        <div style={{ boxShadow: "black 0px 0px 15px -10px" }} class="w-full  py-6 shadow-md rounded-md">
                            <h2 class=" px-10 text-lg font font-semibold tracking-tight text-gray-400">Price Details</h2>
                            <div class="mt-5 border-t-2" ></div>
                            <div class=" px-10 ">
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6">
                                    <h2 class="">Price ( {cartItems.length} item)</h2>
                                    <h2 class="">
                                        {subTotal.toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "INR",
                                        })
                                        }
                                    </h2>
                                </div>
                                <div style={{ justifyContent: 'space-between', }} class="flex mt-6 ">
                                    <h2 class="">Discount</h2>
                                    <h2 style={{ color: 'green' }} class="">
                                        {/* {discount.toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "INR",
                                        })} */}
                                        {discount} %
                                    </h2>
                                </div>
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6">
                                    <h2 class="">Delivery Charges</h2>
                                    <h2 style={{ color: 'green' }} class="">FREE</h2>
                                </div>
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6">
                                    <h2 class="">Margin</h2>
                                    <h2 style={{ color: 'green' }} class="">{resellingMargin}</h2>
                                </div>
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6">
                                    <h2 class="">Wallet amount use</h2>
                                    <h2 style={{ color: 'green' }} class="">{walletCash}</h2>
                                </div>
                                <div class="mt-6 border-t-2" ></div>
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6 text-lg font font-semibold tracking-tight">
                                    <h2 class="">Total Amount</h2>
                                    <h2 style={{}} class="">
                                        {total.toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "INR",
                                        })
                                        }
                                    </h2>
                                </div>
                                <div class="mt-6 border-t-2" ></div>
                                <div style={{ justifyContent: 'space-between' }} class="flex  mt-6 text-lg font font-semibold tracking-tight">
                                    <h2 style={{ color: 'green' }}>You will save 2,000 on this order</h2>

                                </div>
                            </div>

                        </div>
                        <div style={{ boxShadow: "black 0px 0px 15px -10px" }} class="w-full px-10 py-5 mt-4 shadow-md rounded-md">
                            <h2 class="mr-4 text-2xl font font-semibold tracking-tight text-gray-900" >Pay Using</h2>
                            <div class="flex space-between">
                                <div>
                                    <div class=" mt-6 form-check">
                                        <input onClick={() => setPaymentOption("Online")} class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="text-gray-400 text-lg font font-semibold tracking-tight" for="flexRadioDefault1">
                                            Online
                                        </label>
                                    </div>
                                    <div class="mt-6 border-t-2" ></div>
                                    <div class="form-check mt-6">
                                        <input onClick={() => setPaymentOption("Cash On Delivery")} class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        <label class=" text-gray-400 text-lg font font-semibold tracking-tight" for="flexRadioDefault2">
                                            Cash On Delivery
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mt-6 flex flex-end ' style={{ flexDirection: 'row-reverse' }}>
                            {
                                !submitLoading &&
                                <button  onClick={payNow} class="px-6 py-2 bg-liluPurple text-white rounded drop-shadow">Pay Now</button>

                            }
                            {
                                submitLoading &&
                                <button disabled={true} class="px-10 py-2 bg-liluPurple text-white rounded drop-shadow">
                                    <div className="spinner-border h-6 w-6 rounded-full animate-spin" />
                                </button>

                            }
                        </div>
                    </div>


                </div>




            </div>


            {showModal &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Select the address
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    {
                                        addresses.map((add, i) => {
                                            return (
                                                <>
                                                    <div class=" mt-3 form-check">
                                                        <input onClick={() => setAddress(add)} class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                            type="radio" name="addressRadio" id={add.id} />
                                                        <label class="text-gray-400 text-lg font font-semibold tracking-tight" for={add.id}>
                                                            {add.address_line_1}
                                                        </label>
                                                    </div>
                                                    {
                                                        addresses.length !== i + 1 &&
                                                        <div class="mt-3 border-t-2" ></div>
                                                    }

                                                </>
                                            )
                                        })
                                    }


                                    {/* <div class="form-check mt-3">
                                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio" name="addressRadio" id="addressRadio2" checked />
                                        <label class=" text-gray-400 text-lg font font-semibold tracking-tight" for="addressRadio2">
                                            Cash On Delivery
                                        </label>
                                    </div> */}
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }

            <Footer />
        </>
    )
}
