import React, { useState } from "react";
import useAuth from "../../config/AuthContext";
import Header from "../../components/header/Header";
import logo from "../../assets/img/logo5.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [loginError, setLoginError] = useState({ error: false, msg: "" })

  const handleLogin = async() => {
    var msg= await login(email, password);
    console.log("Message",msg)
    setLoginError({error:true,msg})
  };

  return (
    <>
      <Header hideLogin={true} />
      <div id="login-page" className="flex h-screen w-screen">
        <div id="image-container" className="flex flex-1 border-2 border-r justify-center items-center">
          <img src={logo} alt="logo" />
        </div>
        <div id="login-form" className="flex flex-1 items-center justify-center min-h-screen">
          <div className="px-8 py-6 w-120 mt-4 text-left bg-white">
            <h3 className="text-2xl font-bold">Login to your account</h3>
            <div className="mt-4">
              <div>
                <label className="block" for="email">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label className="block">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {
                loginError.error &&
                <div>
                  <span style={{ color: 'red' }}>{loginError.msg}</span>
                </div>
              }

              <div className="flex items-baseline mb-4 justify-between">
                <button
                  className="flex-1 px-6 py-2 mt-4 text-white bg-liluPurple rounded-lg hover:bg-blue-900"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
              <div className="flex justify-between">
                <a href="/forgot-password" className="text-sm text-blue-600 hover:underline">
                  Forgot password?
                </a>
                <a href="/register" className="text-sm text-blue-600 hover:underline">
                  New user? Sign up!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
