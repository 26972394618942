/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { useToken } from "../../config/useToken";
import EmptyCart from "../../assets/img/empty_cart.svg";
import { useNavigate } from "react-router-dom";

const Cart = ({ open, setOpen }) => {
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { getCart, getWishList, removeFromWishList, increaseQuantity, decreaseQuantity } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    calculateSubTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  useEffect(() => {
    fetchAllCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const calculateSubTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * item.quantity;
    });
    setSubTotal(total);
  };




  const fetchAllCartItems = async () => {
    //setLoading(true)
    try {
      var items = await getWishList();
      setCartItems(items)
      calculateSubTotal();
    }
    catch (err) {
      console.log(err)
    }
    setLoading(false);
  }

  const removeItem = async (product) => {
    setLoading(true);
    try {
      removeFromWishList(product);
      fetchAllCartItems();
    }
    catch (err) {
      console.log(err)
    }

    setLoading(false);
  }



  // if (loading) {
  //   //   return <div className="h-72 flex justify-center items-center">
  //   //   <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
  //   // </div>;
  //   return (<div></div>)
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 20 }}
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setTimeout(() => {
            setOpen(false);
          }, 500);
        }}
      >
        
        {
         !loading&&
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div id="wishlist-sidebar" className="fixed inset-y-0 pt-12 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Wish List
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {cartItems.length > 0 &&
                              cartItems.map((product) => (
                                <li

                                  key={product.id} className="py-6 flex"
                                >
                                  <div
                                    onClick={() => {
                                      navigate(`/product/${product.id}`);
                                    }}
                                    className="flex flex-shrink-0 w-24 h-24 border justify-center items-center border-gray-200 rounded-md overflow-hidden"
                                  >
                                    <img src={product?.image} className="max-w-full max-h-full object-center object-cover" />
                                  </div>

                                  <div className="ml-4 flex-1 flex flex-col">
                                    <div
                                      onClick={() => navigate(`/product/${product.id}`)}
                                      className="flex justify-between text-base font-medium text-gray-900"
                                    >
                                      <h3 className="flex-2">
                                        <a href={product.href}>{product.name}</a>
                                      </h3>
                                      <p className="flex-1 justify-end text-right ml-4">
                                        {product.price.toLocaleString("en-IN", {
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency: "INR",
                                        })}
                                      </p>
                                    </div>
                                    <div className="flex-1 flex items-end justify-between text-sm">


                                      <div className="flex">
                                        <button
                                          className="font-medium text-indigo-600 hover:text-indigo-500"
                                          onClick={() => {
                                            removeItem(product.id);
                                          }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))

                            }
                          </ul>
                        </div>
                      </div>
                      {cartItems.length === 0 ? (
                        <div
                          id="wishlist-sidebar-body"
                          className="w-full flex flex-col justify-center items-center"
                          style={{ height: "calc(100% - 3.5rem)" }}
                        >
                          <img
                            className="h-1/2 w-1/2"
                            src={EmptyCart}
                            alt="Empty Cart"
                          />
                          <p className="text-gray-500 text-center mt-4">
                            Your Wishlist is empty
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>


                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        }
      </Dialog>
    </Transition.Root>
  );
};

export default Cart;
