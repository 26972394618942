import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

let counter = 0;
let filteredImagesLen;

const Carousel = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const slideRef = useRef();

  const getImages = async () => {
    const res = await axios.get(
      "https://lilucommercial.herokuapp.com/api/ads/floating-ads"
    );
    res.data.data.map((item) => {
      setImages((images) => [...images, item.image]);
    });
    filteredImagesLen = res.data.data.length;
    setData(res.data.data);
  };

  const handleLink = () => {
    data[currentIndex].type === "Product"
      ? navigate(`/product/${data[currentIndex].product}`)
      : data[currentIndex].type === "Category"
      ? navigate(`/category/${data[currentIndex].category_name}/${data[currentIndex].category}`)
      : navigate(`/sub-category/${data[currentIndex].sub_category_name}/${data[currentIndex].sub_category}`);
  };

  useEffect(() => {
    getImages();
    startSlider();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const startSlider = () => {
    setInterval(() => {
      handleOnNextClick();
    }, 6000);
  };

  const handleOnNextClick = () => {
    console.log("next")
    counter = (counter + 1) % filteredImagesLen;
    setCurrentIndex(counter);
  };
  const handleOnPrevClick = () => {
    console.log("pre")
    counter = (currentIndex + filteredImagesLen - 1) % filteredImagesLen;
    setCurrentIndex(counter);
  };

  if (loading) {
    return (
      <div
        ref={slideRef}
        className="m-2 bg-white rounded shadow-md border-slate-700 select-none relative"
        style={{
          width: "calc(100% - 1rem)",
          boxShadow: "0px 0px 10px -5px black",
          zIndex: "0",
        }}
      >
        <div className="h-72 flex justify-center items-center">
          <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div
    id="crousel-page"
      ref={slideRef}
      className="relative m-2 bg-white rounded shadow-md border-slate-700 select-none"
      style={{
        width: "calc(100% - 1rem)",
        boxShadow: "0px 0px 10px -5px black",
        zIndex: "0",
      }}
    >
      <div
        id="crousel"
        className="w-full p-2 h-72"
        onClick={() => {
          handleLink();
        }}
      >
        <img
          className="h-full object-cover"
          src={images[currentIndex]}
          alt=""
        />
      </div>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 left-0 h-28 w-10 bg-white rounded-r cursor-pointer"
        onClick={handleOnPrevClick}
      >
        <ArrowLeftIcon className="h-6 w-6 mx-auto" />
      </button>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 right-0 h-28 w-10 bg-white rounded-l cursor-pointer"
        onClick={handleOnNextClick}
      >
        <ArrowRightIcon className="h-6 w-6 mx-auto" />
      </button>
    </div>
  );
};

export default Carousel;
