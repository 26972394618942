import React, { useState } from 'react';
import image from '../../assets/img/iphone.jpg'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/outline'


export default function CheckoutProductCart({ product, increaseQuantity, decreaseQuantity, calculateSubTotal, deleteItem }) {

    const [quantity, setQuantity] = useState(1)
    
    const add = () => {
        //setLoading(true);
        increaseQuantity(product);
        calculateSubTotal();
        //setLoading(false);
    }
    const remove = () => {
        //setLoading(true);
        decreaseQuantity(product);
        calculateSubTotal();
        //setLoading(false);
    }

    return (
        <div id="product-detail" class="mt-6 ">
            <div id="sub" class="flex" style={{ justifyContent: 'space-between' }}>
                <div className="flex-1">
                    <div className="flex h-full w-full justify-center items-center">
                        <img
                            src={product.image[0]}
                            alt="Product"
                            className="max-h-40 mx-auto object-cover object-center"
                        />
                    </div>
                </div>
                <div style={{ flexDirection: 'column' }} className="flex-1 flex justify-center">
                    <h2 class="text-lg  text-2xl font font-semibold tracking-tight text-gray-900">{product.name}</h2>
                    <h2 class="text-lg mt-4 font tracking-tight text-gray-400">Seller: Alankar Bhaiya</h2>
                    {/* Price */}
                    <div className='flex mt-4'>
                        <h2 style={{ color: "rgb(104 100 100)" }} class="text-2xl  font tracking-tight">
                            {product.price.toLocaleString("en-IN",
                                {
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency: "INR",
                                })
                            }
                        </h2>
                        <h2 style={{ color: "rgb(104 100 100)" }} class="ml-6 text-2xl  font line-through tracking-tight">₹60,000</h2>
                    </div>
                </div>
                <div id="delivrBy" style={{ flexDirection: 'row-reverse' }} className="flex flex-2 pt-6">
                    <h2 class="text-lg font tracking-tight text-gray-400 pr-6">Deliver by : 24 March 2022|Free</h2>
                </div>
            </div>

            <div id="quntity" style={{ alignItems: 'center', paddingLeft: '5%' }} className='mt-4 flex'>
                <div onClick={remove} >
                    <MinusCircleIcon style={{ color: '#696464' }} className="h-8 w-8 mx-auto mr-4" />
                </div>
                <div class="text-2xl" >{product.quantity}</div>
                <div onClick={add}><PlusCircleIcon style={{ color: '#696464' }} className="h-8 w-8 mx-auto ml-4" /></div>
                <div>
                    <h2 onClick={()=>{deleteItem(product)}}  class="text-lg ml-6 text-2xl font font-semibold tracking-tight text-gray-900">
                        Remove
                    </h2>
                </div>
            </div>
        </div>
    )
}