import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './CategoryHeader.css';

const CategoryHeader = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://lilucommercial.herokuapp.com/api/header-category")
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (loading)
    return (
      <div className="bg-white min-h-7 w-full drop-shadow">
        <div className="flex sticky max-w-128 mx-auto" />
      </div>
    );

  return (
    <div id="sub-category-main-div" style={{zIndex:8,position:'relative'}} className="bg-white min-h-7 w-full drop-shadow">
      <div className="flex sticky justify-evenly flex-row max-w-128 mx-auto ">
        {data.map((category, index) => (
          <div
            key={index}
            className="pl-6 py-3 px-2 h-28 categoryHeaderMenu"
            onClick={() => {
              navigate(`/category/${category.name}/${category.id}`)
            }
            }
          >
            <div className="mb-1 flex justify-center">
              <div className="flex justify-center items-center h-16 w-16">
                <img src={category.image} alt="" />
              </div>
            </div>
            <div className="text-sm font-medium text-center categoryHeaderMenu">
              {category.name}
              <ul className="categoryHeaderDropdown">
                {
                  category.sub_categories.map((dat,i)=>{
                    return(
                      <li onClick={(e) => {e.stopPropagation();navigate(`/sub-category/${dat.slug}/${dat.id}`) }} class="flex py-4 ">
                        {dat.name}
                      </li>
                    )
                  })
                }
                
               
              </ul>

            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryHeader;
