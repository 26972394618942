import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryTab = ({ id, name, products, slug }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white">
      <div
        className="relative shadow-md rounded-md my-4 mx-auto py-12 px-4 sm:px-6 lg:px-8"
        style={{
          width: "calc(100% - 1rem)",
          boxShadow: "0px 0px 10px -5px black",
        }}
      >
        <button
          className="absolute top-12 right-8 px-4 py-2 bg-liluPurple text-white rounded drop-shadow"
          onClick={() => navigate(`/sub-category/${slug}/${id}`)}
        >
          View All
        </button>
        <h2 className="text-2xl font font-extrabold tracking-tight text-gray-900">
          {name}
        </h2>

        <div id="category-container" className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-6 xl:gap-x-8 border-t-2">
          {products.map((product) => (
            <div
              className="group relative"
              onClick={() => navigate(`/product/${product.id}`)}
            >
              <div className="flex justify-center items-center w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                  src={product.image_1}
                  alt="Product Image"
                  className="max-w-full max-h-3/4 object-center object-cover"
                />
              </div>
              <div className="mt-4 flex-1 justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    {/* <span aria-hidden="true" className="absolute inset-0" /> */}
                    {product.name}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                </div>
                


                {
                  product.discount > 0 ? (
                    <div className="flex gap-4">
                      <h3 className="font-medium text-gray-500 line-through mb-2">₹{product.price}</h3>
                      <h3 className="font-medium text-gray-900 mb-2">₹{(product.price * (100 - product.discount)) / 100}</h3>
                      <h3 className="font-medium text-gray-900 mb-2">{product.discount}% Off</h3>
                    </div>
                  ) : (
                    <h3 className="font-bold mb-2">₹{product.price}</h3>
                  )
                }

              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default CategoryTab;
