import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useToken } from "../../config/useToken";
import logo from '../../assets/img/iphone.jpg'

export default function Orders() {

    const { getOrders } = useToken();

    const [allOrders, setAllOrders] = useState([]);
    const [loading, setLoading] = useState(true)

    const getAllOrders = async () => {
        setLoading(true)
        try {
            const res = await getOrders();
            setAllOrders(res.data.data);
            console.log(res.data.data)
        } catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getAllOrders()


    }, [])



    return (
        <div className="h-full w-full flex flex-col p-6">
            <div className="flex items-center mb-4">
                <h1 className="text-base font-semibold m-0">Orders</h1>
            </div>
            {
                allOrders.map(dat => {
                    return (
                        <div  id="orders-product-page" style={{  marginBottom: '3rem', overflow: 'hidden', width: '80%' }} className="flex flex-row px-4 py-2 rounded border border-gray-100 shadow-md cursor-pointer">
                            
                            <div style={{ height: "100%", flex: '1',display:'flex',alignItems:'center',justifyContent:'center' }} className="mb-2">
                                <div style={{height:'80%',width:'80%'}} className="flex justify-center items-center">
                                    <img
                                        src={dat.image}
                                        alt="imh"
                                        className="max-h-40 mx-auto object-cover object-center"
                                    />
                                </div>
                            </div>

                            <div style={{ paddingLeft: "2rem", paddingRight: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="flex-1">

                                <div className="text-base font-semibold mt-4">
                                    {dat.product}
                                </div>
                                <div>
                                    <div className='font-medium text-gray-900 mt-2 mb-1'>
                                        {`Quantity : ${dat.quantity}`}
                                    </div>

                                    <div className='font-medium text-gray-900 mb-1'>
                                        {`Order Id : ${dat.id}`}
                                    </div>
                                    <div className='font-medium text-gray-900 mb-1'>
                                        {`Amount : ${dat.amount}`}
                                    </div>
                                </div>
                            </div>

                            <div id="btn-cont" style={{ display: 'flex', flexDirection: 'column-reverse' }} className="flex-1">
                                <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="text-base font-semibold mr-4">Cancel</div>
                                    <button
                                        className="bg-liluPurple text-white text-sm font-medium py-2 px-4 rounded w-full "
                                        onClick={() => console.log("track order")}
                                    >
                                        Track Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}
