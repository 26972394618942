import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactImageZoom from "react-image-zoom";
import { FileUploader } from "react-drag-drop-files";
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Review from "../../components/review/Review";
import { useToken } from "../../config/useToken";

import { HeartIcon } from "@heroicons/react/solid";
import OutlineHeartIcon from "@heroicons/react/outline/HeartIcon";
import PencilIcon from '@heroicons/react/outline/PencilIcon';
import CloudUploadIcon from '@heroicons/react/outline/CloudUploadIcon';
import ExternalLinkIcon from '@heroicons/react/outline/ExternalLinkIcon';

let counter = 0;
let filteredImagesLen;
let filteredDetails = [];
let timer;

const props = { width: 400, height: 250, zoomWidth: 500, img: "1.jpg" };

const Product = () => {
  const { productId } = useParams();
  //const [productId,setProductId]=useState(useParams().productId);
  const GLOBAL_URL = "https://lilucommercial.herokuapp.com/api";
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pincode, setPincode] = useState("");
  const [bargainValue, setBargainValue] = useState("");
  const [bargainMsg, setBargainMsg] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeSuccess, setPincodeSuccess] = useState(false);
  const { addToCart, getToken, checkIfInWishList, addToWishList, removeFromWishList } = useToken();
  const [addedToWishlist, setaddedToWishlist] = useState(false)
  const [biddingCountDownTime, setBiddingCountDownTime] = useState("")
  const [deliverydateChecked, setdeliverydateChecked] = useState(true)
  const [selectedFile, setSelectedFile] = useState("")

  const fileTypes = ["JPG", "PNG", "GIF"];

  //customisedProduct
  const [customisedProduct, setCustomisedProduct] = useState(false);
  const [selectedCustomisedTab, setSelectedCustomisedTab] = useState(null)
  const [customisedText, setCustomisedText] = useState("")
  const [file, setFile] = useState("")
  const [previewSrc, setPreviewSrc] = useState("")
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const [selectedFontSize, setSelectedFontSize] = useState(1);
  const [selectedImageSize, setSelectedImageSize] = useState(10)

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
  };



  const sendBargainRequest = async (bargainedProduct) => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(
        `https://lilucommercial.herokuapp.com/api/bargain`,
        {
          product_id: productId,
          asked_price: bargainValue,
        },
        config
      )
      .then((res) => {
        addToCart(bargainedProduct);
        setBargainMsg("Accepted and added to cart");
      })
      .catch((err) => {
        setBargainMsg(err.response.data.errors.message);
      });
  };

  const getProduct = async () => {
    var itemDetails = "";
    await axios
      .get(`${GLOBAL_URL}/products/${productId}`)
      .then((res) => {
        setProduct(res.data.data.data);
        checkForProductInWishList(res.data.data.data)
        if ("Customized Gifts" === res.data.data.data.category) {
          setCustomisedProduct(true)
        }
        else {
          setCustomisedProduct(false)
        }
        setImages(res.data.data.data.colors[0].images);
        setSelectedColor(res.data.data.data.colors[0].name);
        filteredImagesLen = res.data.data.data.colors[0].images.length;
        filteredDetails = res.data.data.data.array.filter(
          (arr) => arr.value !== null
        );
        itemDetails = res.data.data.data
      })
      .then(() => {
        setDetails(filteredDetails);
        if (itemDetails.bidding_active)
          calculateBiddingTime(itemDetails.bidding_close_time)
      })
      .finally(() => setLoading(false));

  };

  const onFileChange = file => {
    console.log(file)
    setSelectedFile(file)
  };

  const calculateBiddingTime = (dateTime) => {
    dateTime = new Date(dateTime);
    var currentDateTime = new Date();
    var timeDiff = dateTime - currentDateTime
    //var timeDiff = currentDateTime - dateTime
    setBiddingCountDownTime(dhm(timeDiff))
    timer = setInterval(() => {
      currentDateTime = new Date();
      timeDiff = dateTime - currentDateTime
      setBiddingCountDownTime(dhm(timeDiff))
      console.log("Updated time")
    }, 1000);
  }

  function dhm(t) {
    var cd = 24 * 60 * 60 * 1000, //one day in mili sec
      ch = 60 * 60 * 1000,  // one hr
      cm = 60 * 1000,  //one min
      d = Math.floor(t / cd),
      h = Math.floor((t - d * cd) / ch),
      m = Math.floor((t - d * cd - h * ch) / 60000),
      s = Math.round((t - d * cd - h * ch - m * cm) / 1000),
      pad = function (n) { return n < 10 ? '0' + n : n; };
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    d = d + " days "
    return [d, pad(h) + " hrs ", pad(m) + " mins ", pad(s) + " s"].join(':');
  }

  const checkForProductInWishList = async (pro) => {
    var a = await checkIfInWishList(pro);
    setaddedToWishlist(a);
  }

  const handleAddToWishList = () => {
    let pro = productId
    // console.log(pro)
    // pro.id = productId;
    if (addedToWishlist) {
      removeFromWishList(pro);
      setaddedToWishlist(false);
    }
    else {
      addToWishList(pro);
      setaddedToWishlist(true);
    }

  }


  const slideRef = useRef();

  useEffect(() => {
    getProduct();
    return (() => {
      try {
        clearInterval(timer)
      }
      catch (err) {
        console.log(err)
      }
    })
  }, [useParams().productId]);

  useEffect(() => {
    startSlider();
  }, [useParams().productId]);

  const startSlider = () => {
    setInterval(() => {
      counter = (counter + 1) % filteredImagesLen;
      setCurrentIndex(counter);
    }, 4000);
  };

  const getColorId = (color, colors) => {
    console.log(colors)
    let id = 0
    colors.forEach((col) => {

      if (col.name === color) {
        id = col.id
      }
    })
    return id
  }

  const getFutureDate = () => {
    var day = 2;
    var current = new Date(); //'Mar 11 2015' current.getTime() = 1426060964567
    var followingDay = new Date(current.getTime() + 86400000 * day); // + 1 day in ms
    var fut = followingDay.toISOString().split('T')[0];
    return fut
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <>
      <Header />

      {/* Customised product header */}
      <div className=" product-page flex w-screen max-w-7xl pl-4 mt-14 mx-auto">
        {customisedProduct &&
          <h1 className="mt-14 text-xl font font-semibold tracking-tight text-gray-600">Customise Your Product</h1>
        }
      </div>

      <div id="product-body" className=" product-page flex w-screen max-w-7xl mx-auto">



        <div className="flex-3 m-4">
          <div className="sticky top-16 bottom-0">
            <div
              ref={slideRef}
              id="image-container"
              className="bg-white w-full rounded shadow-md border-slate-700 select-none relative"
              style={{
                boxShadow: "0px 0px 15px -10px black",
                height: "calc(100vh - 200px)",
                // width:'fit-content',
                // margin:'auto'
              }}
            >
              <div className="w-full h-full flex justify-center items-center p-4">

                {selectedCustomisedTab === "addText" &&
                  <div style={{ position: 'absolute', top: '50%', right: '50%', transform: "translate(50%,-50%)" }} >
                    <div style={{ fontSize: selectedFontSize + "rem" }}>
                      {customisedText}
                    </div>
                  </div>
                }



                {selectedCustomisedTab === "uploadDesign" && isPreviewAvailable &&
                  <div style={{ width: selectedImageSize + "%", position: 'absolute', top: '50%', right: '50%', transform: "translate(50%,-50%)" }} >
                    <img
                      className="object-contain h-full w-full"
                      src={previewSrc}
                      alt=""
                    />
                  </div>
                }

                <img
                  className="object-contain h-full w-full"
                  src={images[currentIndex]}
                  alt=""
                />
                <div onClick={handleAddToWishList} style={{ position: 'absolute', top: "2rem", right: "2rem", cursor: 'pointer' }}>
                  {
                    !addedToWishlist &&
                    <OutlineHeartIcon style={{ color: 'rgb(128 128 128)' }} className="h-10 w-10" />
                  }
                  {
                    addedToWishlist &&
                    <HeartIcon style={{ color: 'red' }} className="h-10 w-10" />
                  }

                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between h-24 w-full p-1 border-2 rounded-md my-4">
              {images.map((img, index) => {
                return (
                  <div
                    key={index}
                    className={`flex justify-center items-center p-2 h-full w-20 ${index === currentIndex
                      ? "rounded border-2 border-liluPurple"
                      : ""
                      }`}
                    onMouseEnter={() => {
                      setCurrentIndex(index);
                      slideRef.current.classList.add("fade-anim");
                    }}
                  >
                    <img className="object-cover h-full" src={img} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex-4 m-4">

          {/* Top right card */}
          <div id="description-container" className="w-full px-10 py-5 shadow-md rounded-md">

            {/* First row */}
            <div className="flex first-row" style={{ justifyContent: 'space-between' }} >
              <div id="name_and_brand" style={{ maxWidth: '50%' }}>
                <h1 className="text-lg font font-bold tracking-tight text-gray-400">
                  {product.brand}
                </h1>
                <h1 className="text-lg mb-6 font font-semibold tracking-tight text-gray-600">
                  {product.name}
                </h1>
              </div>

              {/* Bidding section starts*/}
              {
                product.bidding_active &&
                <div id="bidding-time">
                  <div>
                    <h1 className="text-lg ml-4 font font-semibold text-gray-600 custom-label">
                      Bidding Time
                    </h1>
                  </div>
                  <div id="countdown" style={{ backgroundColor: 'rgb(17 24 39)', borderRadius: '5rem', padding: '0.5rem 1.5rem', textAlign: 'center' }} >
                    <h1 className=" font text-white">
                      {biddingCountDownTime}
                    </h1>
                  </div>
                </div>
              }
              {/* Bidding section ends*/}

            </div>

            {/* Second row for lined product cost , product cost and discount  */}
            <span
              className=" second-row mr-6 text-2xl font font-medium tracking-tight"
              style={
                product.discount > 0 && product.discount !== null
                  ? {
                    textDecoration: "line-through",
                    color: "rgb(107 114 128)",
                  }
                  : {
                    textDecoration: "none",
                    color: "black",
                  }
              }
            >
              ₹{product.price}
            </span>

            {/* Second with discount and product price  */}
            {product.discount > 0 && product.discount !== null &&
              <span className="second-row-discount">
                <span className="mr-4 text-2xl font font-semibold tracking-tight text-gray-900 ">
                  ₹{product.price - (product.discount * product.price) / 100}
                </span>
                <span className="text-lg font font-semibold tracking-tight text-gray-900">
                  {product.discount}% off
                </span>
              </span>
            }


            {/* Third row out of stock */}
            {product.inventory === 0 &&
              <>
                <br />
                <span style={{ color: 'red' }} className="text-lg  font font-semibold tracking-tight text-gray-900 third-row">
                  Out of stock
                </span>
              </>
            }

            {/* Fourth row colours */}
            <div className="flex mt-6 flex-row items-center fourth-row ">
              {product.colors.length > 0 && (
                <h1 className="mr-4 my-8 text-sm font-semibold tracking-tight text-gray-600">
                  COLORS:
                </h1>
              )}
              {product.colors.length > 0 &&
                <div className="flex flex-row items-center">
                  {
                    product.colors.map((color) => (
                      <div
                        className="flex flex-col justify-center items-center h-14 w-14 p-1 border-2 rounded-md mr-3 cursor-pointer"
                        key={color.id}
                        onClick={() => {
                          if (selectedColor === color.name) {
                            setSelectedColor("");
                          } else {
                            setSelectedColor(color.name);
                            setImages(color.images);
                            setCurrentIndex(0);
                            filteredImagesLen = color.images.length;
                          }
                        }}
                        style={
                          selectedColor === color.name
                            ? { borderColor: "rgb(17 24 39)" }
                            : {}
                        }
                      >
                        <img
                          className="h-full w-auto"
                          src={color.images[0]}
                          alt=""
                        />
                      </div>
                    ))
                  }
                </div>

              }
              {product.colors.length > 0 && (
                <h1 className="mr-4 my-8 text-xs font-medium tracking-tight text-gray-600">
                  {selectedColor}
                </h1>
              )}
            </div>

            {/* Fifth row size */}
            <div className="fifth-row flex flex-row items-center">
              {product.sizes.length > 0 && (
                <h1 className="mr-4 my-8 text-sm font-semibold tracking-tight text-gray-600">
                  SIZE:
                </h1>
              )}
              {product.sizes.length > 0 &&

                <div className="flex flex-row items-center">
                  {
                    product.sizes.map((size) => (
                      <div
                        key={size.size}
                        className="flex justify-center items-center h-10 w-10 border-2 rounded-md mr-3"
                        onClick={() =>
                          size.available ? (
                            selectedSize === size.size ? (
                              setSelectedSize("")
                            ) : (
                              setSelectedSize(size.size)
                            )
                          ) : (
                            <></>
                          )
                        }
                        style={{
                          borderColor: size.available
                            ? "rgb(17 24 39)"
                            : "rgb(209 213 219)",
                          color:
                            size.available && size.size === selectedSize
                              ? "white"
                              : size.available
                                ? "rgb(17 24 39)"
                                : "rgb(209 213 219)",
                          backgroundColor:
                            size.size === selectedSize ? "rgb(17 24 39)" : "white",
                          cursor: size.available ? "pointer" : "not-allowed",
                        }}
                      >
                        {size.size}
                      </div>
                    ))}
                </div>

              }
            </div>


            {/* Sixth row Pincode */}
            <div className="sixth-row flex flex-row items-center mt-8 mb-4">
              <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                PIN CODE:
              </h1>
              {/* PINCODE INPUT */}
              <div className="flex flex-row items-center">
                <input
                  className="py-1 px-2 border-2 rounded-md"
                  style={
                    pincodeError
                      ? { borderColor: "rgb(239 68 68)" }
                      : pincodeSuccess
                        ? { borderColor: "rgb(95, 76, 242)" }
                        : { borderColor: "rgb(209 213 219)" }
                  }
                  type="text"
                  placeholder="Enter Pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <button
                  className="ml-4 py-2 px-3  bg-liluPurple text-white font-semibold text-sm rounded-md"
                  onClick={() => {
                    if (pincode.length === 6) {
                      if (
                        product.servicible_pincodes.find(
                          (o) => o.pincode === pincode
                        )
                      ) {
                        setPincodeError(false);
                        setPincodeSuccess(true);
                      } else {
                        setPincodeError(true);
                        setPincodeSuccess(false);
                      }
                    } else {
                      setPincodeError(true);
                      setPincodeSuccess(false);
                    }
                  }}
                >
                  Check Availability
                </button>
              </div>
            </div>

            {/* Seventh row Pincode error */}
            {pincodeError ? (
              <div className="flex flex-row items-center">
                <p className="text-xs font-medium text-red-500">
                  Pincode not available
                </p>
              </div>
            ) : pincodeSuccess ? (
              <div className="flex flex-row items-center">
                <p className="text-xs font-medium text-indigo-500">
                  Available for Delivery!
                </p>
              </div>
            ) : (
              <></>
            )}


            {/* Eigth row for checkbox for fast date */}
            {product.category === "Quick Store" &&
              <div>
                <div >
                  <input onChange={(e) => { setdeliverydateChecked(e.target.checked); console.log(e.target.checked) }} class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckChecked" checked={deliverydateChecked} />
                  <label className="mr-4 text-sm font-semibold tracking-tight text-gray-600" for="flexCheckChecked">
                    DELIVERY DATE
                  </label>
                </div>
                {
                  deliverydateChecked &&

                  <div class="mt-4 datepicker relative form-floating mb-3 xl:w-96">
                    <input type="date"
                      //min={new Date().toISOString().split('T')[0]}
                      min={getFutureDate()}
                      className="py-1 px-2 border-2 rounded-md" />
                  </div>
                }
              </div>
            }



            {/* Ninth row Bidding section */}
            {
              product.bidding_active &&
              <>
                <button
                  className=" mt-8 mb-2 accordion-button relative flex items-center w-full border-0 rounded-none transition focus:outline-none collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {/* <h2 className="accordion-header text-2xl text-gray-900 my-6 font font-semibold tracking-tight"> */}
                  <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                    BIDDING
                  </h1>
                </button>
                <div
                  id="collapseTwo"
                  className="accordion-collapse show collapse ninth-row"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body pt-2">
                    <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                      You can set the bidding amount for this product
                    </h1>
                    <div className="flex flex-row items-center mt-4 mb-4">
                      <input
                        className="py-1 px-2 border-2 rounded-md"

                        type="text"
                        placeholder="Enter Bidding Value"
                      />
                      <button
                        className="ml-4 py-2 px-3 text-white font-semibold text-sm rounded-md"
                        style={{ backgroundColor: 'grey' }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </>

            }



            {/* Tenth row Bargain section */}
            {product.isBargain &&
              <>
                <button
                  className="mt-6 accordion-button relative flex items-center border-0 shadow-0 rounded-none transition focus:outline-none collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#bargain"
                  aria-expanded="false"
                  aria-controls="bargain"
                >
                  <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                    BARGAIN
                  </h1>
                </button>
                {/* PINCODE INPUT */}
                <div
                  id="bargain"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="flex flex-row items-center mt-4 mb-4">
                    <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                      You only get, one chance to get this product at bargained
                      price!
                    </h1>
                  </div>
                  {bargainMsg !== "" ? (
                    <div className="flex flex-row items-center mt-4 mb-4">
                      <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                        {bargainMsg}
                      </h1>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="accordion-body mt-4">
                    <input
                      className="py-1 px-2 border-2 rounded-md"
                      style={
                        pincodeError
                          ? { borderColor: "rgb(239 68 68)" }
                          : pincodeSuccess
                            ? { borderColor: "rgb(95, 76, 242)" }
                            : { borderColor: "rgb(209 213 219)" }
                      }
                      type="text"
                      placeholder="Enter Bargain Value"
                      value={bargainValue}
                      onChange={(e) => setBargainValue(e.target.value)}
                    />
                    <button
                      className="ml-4 py-2 px-3  bg-liluPurple text-white font-semibold text-sm rounded-md"
                      disabled={
                        (product.sizes.length > 0 && selectedSize === "") ||
                        selectedColor === "" ||
                        pincode.length !== 6
                      }
                      style={{
                        backgroundColor:
                          selectedSize === "" ||
                            selectedColor === "" ||
                            pincode.length !== 6
                            ? "grey"
                            : "white",
                        color:
                          selectedSize === "" ||
                            selectedColor === "" ||
                            pincode.length !== 6
                            ? "white"
                            : "rgb(95, 76, 242)",
                        borderColor:
                          selectedSize === "" ||
                            selectedColor === "" ||
                            pincode.length !== 6
                            ? "grey"
                            : "rgb(95, 76, 242)",
                      }}
                      onClick={() => {
                        const cartProduct = {
                          id: `${productId}${selectedColor}${selectedSize}_bargained`,
                          name: product.name,
                          quantity: 1,
                          price: bargainValue,
                          size: selectedSize,
                          color: selectedColor,
                          image: images,
                          pincode: pincode,
                        };
                        sendBargainRequest(cartProduct);
                      }}
                    >
                      Check
                    </button>
                  </div>
                </div>
              </>
            }

            {/* Eleventh row  */}
            <div className="flex flex-row w-full h-14 py-2 my-10 eleventh-row">
              <button
                className="flex-1 border-2 mr-2 border-liluPurple text-indigo-500 font font-semibold tracking-tight text-sm rounded-md"
                disabled={
                  product.sizes.length > 0
                    ? (selectedSize === "" ||
                      selectedColor === "" ||
                      pincode.length !== 6 ||
                      product.inventory === 0)
                    : (selectedColor === "" || pincode.length !== 6 || product.inventory === 0)
                }
                style={{
                  backgroundColor:
                    (product.sizes.length > 0
                      ? (selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0)
                      : (selectedColor === "" || pincode.length !== 6 || product.inventory === 0))
                      ? "grey"
                      : "white",
                  color:
                    (product.sizes.length > 0
                      ? (selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0)
                      : (selectedColor === "" || pincode.length !== 6 || product.inventory === 0))
                      ? "white"
                      : "rgb(95, 76, 242)",
                  borderColor:
                    (product.sizes.length > 0
                      ? (selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0)
                      : (selectedColor === "" || pincode.length !== 6 || product.inventory === 0))
                      ? "grey"
                      : "rgb(95, 76, 242)",
                }}
                onClick={() => {

                  const cartProduct = {
                    // id: `${productId}${selectedColor}${selectedSize}`,
                    id: `${productId}`,
                    name: product.name,
                    quantity: 1,
                    price: (product.price * (100 - product.discount)) / 100,
                    size: selectedSize,
                    color: selectedColor,
                    colorId: getColorId(selectedColor, product.colors),
                    image: images,
                    pincode: pincode,
                  };
                  addToCart(cartProduct);
                }}
              >
                ADD TO CART
              </button>
              <button className="flex-1 ml-2 bg-liluPurple text-white font font-semibold tracking-tight text-sm rounded-md">
                BUY NOW
              </button>
            </div>


            {/* Twelvth Product Details Collapsable */}

            <button
              className="accordion-button relative flex items-center w-full border-0 rounded-none transition focus:outline-none collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <h1 className="accordion-header text-2xl text-gray-900 my-6 font font-semibold tracking-tight">
                Product Details
              </h1>
            </button>

            {/* Thirtheenth collaps open */}
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body pt-4">
                <table className="table-fixed w-full">
                  <thead>
                    <tr>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {details.map((detail) => (
                      <tr className="h-10" key={detail.title}>
                        <td className="font-semibold text-gray-500">
                          {detail.title}
                        </td>
                        <td className="font-semibold text-gray-800">
                          {detail.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>




          </div>

          {/* customised items */}
          {customisedProduct &&
            <div
              id="feedback-container"
              className="w-full p-4 mt-4 shadow-md rounded"
              style={{
                boxShadow: "0px 0px 15px -10px black",
              }}
            >


              <div className="flex" style={{ marginTop: '2rem', justifyContent: 'space-around' }}>
                {/*add text */}
                <div onClick={() => setSelectedCustomisedTab("addText")} className="flex" style={{ width: '20%', padding: '1rem', justifyContent: 'center', alignItem: 'center', borderBottom: 'solid 1px' }}>
                  <PencilIcon style={{ color: 'rgb(0 0 0)' }} className="h-6 w-6" />
                  <h1 className="text-xl pl-2 font font-semibold tracking-tight text-gray-600">
                    Text
                  </h1>
                </div>
                {/* add icon */}
                <div onClick={() => setSelectedCustomisedTab("addIcons")} className="flex" style={{ width: '20%', padding: '1rem', justifyContent: 'center', alignItem: 'center', borderBottom: 'solid 1px' }}>
                  <ExternalLinkIcon style={{ color: 'rgb(0 0 0)' }} className="h-6 w-6" />
                  <h1 className="text-xl pl-2 font font-semibold tracking-tight text-gray-600">
                    Icon
                  </h1>
                </div>

              </div>
              <div className="flex mb-4" style={{ marginTop: '2rem', justifyContent: 'space-around' }}>
                {/* upload design */}
                <div onClick={() => setSelectedCustomisedTab("uploadDesign")} className="flex" style={{ width: '30%', padding: '1rem', justifyContent: 'center', alignItem: 'center', borderBottom: 'solid 1px' }}>
                  <CloudUploadIcon style={{ color: 'rgb(0 0 0)' }} className="h-6 w-6" />
                  <h1 className="text-xl pl-2 font font-semibold tracking-tight text-gray-600">
                    Upload Design
                  </h1>
                </div>
              </div>

            </div>
          }


          {/* drag and drop */}
          {customisedProduct && selectedCustomisedTab === "uploadDesign" &&
            <div className=" product-page flex mb-6 max-w-7xl mx-auto">
              <div
                className="w-full p-4 mt-4 shadow-md rounded"
                style={{
                  boxShadow: "0px 0px 15px -10px black",
                }}
              >
                <div className="dragAnaDropDiv" style={{ backgroundColor: 'rgb(235 235 235)', height: '10rem', width: '100%' }}>

                  <Dropzone style={{ backgroundColor: 'green' }} onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: 'drop-zone' })} >
                        <input {...getInputProps()} />
                        <p>Drag and drop a file OR click here to select a file</p>
                        {file && (
                          <div>
                            <strong>Selected file:</strong> {file.name}
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="flex mt-5" style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                  <div class="relative pt-1">
                    <label for="customRange1" class="form-label">Select the image size</label>
                    <input
                      type="range"
                      class="
                            form-range
                            appearance-none
                            w-full
                            h-6
                            p-0
                            bg-transparent
                            focus:outline-none focus:ring-0 focus:shadow-none
                          "
                      min="5"
                      max="100"
                      step="0.25"
                      value={selectedImageSize}
                      id="customRange1"
                      onChange={(e) => setSelectedImageSize(e.target.value)}
                    />
                  </div>
                  <button
                    style={{ height: 'fit-content' }}
                    className=" py-2 px-3  bg-liluPurple text-white font-semibold text-sm rounded-md"
                    onClick={() => {
                      console.log("Hii")
                    }}
                  >
                    Submit
                  </button>
                </div>

              </div>

            </div>
          }

          {/* Text input */}
          {customisedProduct && selectedCustomisedTab === "addText" &&
            <div className=" product-page flex mb-6 max-w-7xl mx-auto">
              <div
                className="w-full p-4 mt-4 shadow-md rounded"
                style={{
                  boxShadow: "0px 0px 15px -10px black",
                }}
              >
                <textarea
                  class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
                  rows="3"
                  placeholder="Enter your text here..."
                  onChange={(e) => setCustomisedText(e.target.value)}
                  value={customisedText}
                ></textarea>
                <div className="flex mt-5" style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                  <div class="relative pt-1">
                    <label for="customRange1" class="form-label">Select the text size</label>
                    <input
                      type="range"
                      class="
                            form-range
                            appearance-none
                            w-full
                            h-6
                            p-0
                            bg-transparent
                            focus:outline-none focus:ring-0 focus:shadow-none
                          "
                      min="1"
                      max="5"
                      step="0.5"
                      value={selectedFontSize}
                      id="customRange1"
                      onChange={(e) => setSelectedFontSize(e.target.value)}
                    />
                  </div>
                  <button
                    style={{ height: 'fit-content' }}
                    className=" py-2 px-3  bg-liluPurple text-white font-semibold text-sm rounded-md"
                    onClick={() => {
                      console.log("Hii")
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          }

          {/* Icon input */}
          {customisedProduct && selectedCustomisedTab === "addIcons" &&
            <div className=" product-page flex w-screen mb-6 max-w-7xl mx-auto">
              <div
                className="w-full p-4 mt-4 shadow-md rounded"
                style={{
                  boxShadow: "0px 0px 15px -10px black",
                }}
              >
                Displaying 412 icons:
                <div className="flex mt-5" style={{ flexDirection: 'row-reverse' }} >
                  <button
                    className=" py-2 px-3  bg-liluPurple text-white font-semibold text-sm rounded-md"
                    onClick={() => {
                      console.log("Hii")
                    }}
                  >
                    Select Icon
                  </button>
                </div>
              </div>
            </div>
          }

          {/* Rating and reviews */}
          <div
            id="feedback-container"
            className="w-full p-4 mt-4 shadow-md rounded"
            style={{
              boxShadow: "0px 0px 15px -10px black",
            }}
          >
            <h1 className="text-lg font font-bold tracking-tight text-gray-600">
              Ratings & Reviews
            </h1>
            {product.reviews.length === 0 ? (
              <div className="flex flex-col items-center my-5 justify-center">
                <img
                  className="object-cover h-32 w-32"
                  src="https://i.ibb.co/8zGR9R1/undraw-Notify-re-65on.png"
                  alt=""
                />
                <h1 className="text-sm tracking-tight text-gray-400">
                  Be the first to review this product
                </h1>
              </div>
            ) : (
              product?.reviews.map((review) => {
                return (
                  <Review
                    key={`${review.from}${review.heading}`}
                    start={review.start}
                    heading={review.heading}
                    body={review.body}
                    images={review.images}
                    from={review.from}
                  />
                );
              })
            )}
          </div>








        </div>

      </div>









      <Footer />
    </>
  );
};

export default Product;
